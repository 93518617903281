// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: true,
	apiLink: "https://dwm-middleware-stage-1.ischoolconnect.com",
	tokenKey: "SOP_ACCESS_TOKEN",
	cookieDomain: "ischoolconnect.com",
	cdnBaseUrl: "https://stage-cdn.ischoolconnect.com",
	studentLogrocketId: "comozl/student-dashboard-stage-b6wdv",
	advisorLogrocketId: "comozl/advisor-dashboard-stage-pcxny",
	advisorBackendUrl: "https://stage-2-advisor-api.ischoolconnect.com"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

